





// Core
import { UsersActions } from '@/store/users/types'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Styles
import './admin.scss'

// Modules
const NSUsers = namespace('usersModule')

@Component({
  name: 'Admin',
})
export default class AdminComponent extends Vue {
  @NSUsers.Action(UsersActions.A_FETCH_USER_ROLES)
  private fetchUserRoles!: () => Promise<any>

  private created() {
    this.fetchUserRoles()
  }
}
